
  .submit-wrapper button {
    cursor: pointer;
  }

  .button-submit {
    min-width: 300px;

    @media screen and (max-width: 500px) {
      min-width: 0px;
      flex: 0 0  100%;
    }
  }
